<template>
  <div class="checkbox-input-item">
    <label
      :class="{ 'checkbox-input-item__label--disabled': $attrs.disabled }"
      class="m-0 flx flx--items-center txt--pointer"
    >
      <!-- :name="name"  -->
      <!-- :checked="checked" -->
      <input
        v-model="compValue"
        :value="checkboxValue"
        v-bind="$attrs"
        type="checkbox"
        class="checkbox-input-item__input checkbox animated-dot-new bor--all bor--radius-01 bor--transp-02"
        :class="[
          verticalAlign && `checkbox-input-item__input--${verticalAlign}`,
          { 'bg--transp-01': $attrs.disabled },
        ]"
      >
      <span
        v-if="label"
        :class="textClasses"
        class="checkbox-input-item__label txt--12px txt--height-14px txt--color-04 ml-10"
      >
        {{ label }}
      </span>
      <slot />
    </label>
  </div>
</template>

<script>
import CheckboxInputItemModel from '@/lib/data-models/inputs/CheckboxInputItemModel.ts'

export default {
  name: 'CheckboxInputItem',
  props: {
    value: {
      type: [Array, Boolean],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    checkboxValue: {
      type: CheckboxInputItemModel,
      default: () => {},
    },
    verticalAlign: {
      type: String,
      default: '',
    },
    textClasses: {
      type: String,
      default: 'txt--12px',
    },
  },
  computed: {
    compValue: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
// $rad: 18px;

.checkbox-input-item {
  &__label {
    &--disabled {
      pointer-events: none;
    }
  }

  &__input {
    margin: 0;

    &--top {
      top: toRem(5px);
      align-self: flex-start;
    }
  }
}
</style>
