<template>
  <component
    :is="useV2 ? 'auth-container-v2' : 'auth-container'"
    :form-name="!modalTwoFaOpened ? $t('loginPage.login') : ''"
    :hide-header="modalTwoFaOpened"
    :steps="steps"
    show-links
    class="auth-login"
    @submit="onFormSubmit"
  >
    <template #default>
      <div v-if="!modalTwoFaOpened" class="auth-register__form">
        <exc-input
          v-model="$v.email.$model"
          :field="$v.email"
          :optional-errors="{
            ...customErrors,
            maxLength: $t('validators.maxLength', { max: 255 }),
            ...mix_getServerError('email'),
          }"
          :label="$t('loginPage.eMail')"
          type="email"
          autocomplete="email"
          size="s"
          class="auth-login__email"
          @input="mix_clearServerError('email')"
        />
        <password-input
          v-model="$v.password.$model"
          :label="$t('loginPage.password')"
          :optional-errors="{
            ...customErrors,
            minLength: $t('validators.minLength', { min: 8 }),
            maxLength: $t('validators.maxLength', { max: 20 }),
            ...mix_getServerError('password'),
          }"
          :field="$v.password"
          name="password"
          size="s"
          class="auth-login__password"
          autocomplete="current-password"
          @focus="mix_clearServerError('password')"
        />

        <div class="login-text__reset-wrapper mt-24">
          <div class="login-text__remember-device-wrapper">
            <exc-checkbox id="register-checkbox" v-model="rememberMe" />
            <span class="txt--color-04 txt-14px txt--height-18px pl-26">
              {{ $t('loginPage.rememberDevice') }}
            </span>
          </div>
        </div>
      </div>
      <div v-else>
        <confirmation
          :two-fa="twoFa"
          :device="device"
          :device-code-resend-in="deviceConfirmationCodeExpiresIn"
          :email="emailConfirmation"
          :email-code-resend-in="emailConfirmationCodeExpiresIn"
          :extended-data="loginData"
          :is-modal="false"
          :back-btn-text="$t('loginPage.btnBack')"
          @close="closeModal"
          @back="closeModal"
        />
      </div>
    </template>
    <template v-if="!modalTwoFaOpened" #btn>
      <exc-button class="login-button" size="s" :loading="loading" full-width>
        <span>
          {{ $t('loginPage.signIn') }}
        </span>
      </exc-button>
    </template>
    <template v-if="!modalTwoFaOpened" slot="bottom">
      <div class="login-text__wrapper mb-24">
        <div class="txt--color-03 txt--12px txt--height-16px txt--weight-600">
          {{ $t('loginPage.dontHaveAccountText') }}
        </div>
        <exc-link
          :to="localePath('/register')"
          class="txt--main txt--12px txt--height-16px txt--weight-600 ml-6"
        >
          {{ $t('loginPage.register') }}
        </exc-link>
      </div>

      <div>
        <exc-link
          :to="localePath('/reset-password')"
          class="txt--color-04 txt--12px txt--height-16px txt--weight-600"
        >
          {{ $t('loginPage.forgotPasswordText') }}
        </exc-link>
      </div>
    </template>
  </component>
</template>

<script>
import {
  ExcButton,
  ExcInput,
  ExcLink,
  TdxInput,
  ExcCheckbox,
} from '@/components/common'
import { validationMixin } from 'vuelidate'
import { mapMutations, mapState } from 'vuex'
import { AUTH_CONTAINER_V2, REMEMBER_ME, STATUS_CODE } from '@/constants'
import { required, email } from 'vuelidate/lib/validators'
import { serverFormMixin } from '@/mixins/serverFormMixin'
import AuthFormSubmit from '@/mixins/auth/AuthFormSubmit'
import AuthMixinCaptcha from '@/mixins/auth/AuthMixinCaptcha'
import AuthMixinRoutes from '@/mixins/auth/AuthMixinRoutes'
import MixinNavLangSwitch from '@/mixins/MixinNavLangSwitch'
import { domainWithoutSubdomain, getDefaultHeadData } from '@/lib/utils'
import { notifyError } from '@/lib/bus'

const TEMP_ID_KEY = 'temp_user_id'

export default {
  name: 'Login',
  components: {
    ExcButton,
    ExcInput,
    ExcLink,
    TdxInput,
    ExcCheckbox,
    AuthContainer: () =>
      import(
        /* webpackMode: "eager" */ '~/components/containers/AuthContainer.vue'
      ),
    CheckboxInputItem: () =>
      import(
        /* webpackMode: "eager" */ '@/components/ui-kit/radio-checkbox/CheckboxInputItem.vue'
      ),
    AuthContainerV2: () =>
      import(
        /* webpackMode: "eager" */ '@/components/containers/AuthContainerV2.vue'
      ),
    PasswordInput: () =>
      import(
        /* webpackMode: "eager" */ '@/components/ui-kit/text-input/PasswordInput.vue'
      ),
    Confirmation: () =>
      import(
        /* webpackMode: "eager" */ '@/components/confirmation/Confirmation.vue'
      ),
  },
  mixins: [
    MixinNavLangSwitch,
    AuthMixinRoutes,
    AuthMixinCaptcha,
    AuthFormSubmit,
    validationMixin,
    serverFormMixin,
  ],
  middleware: 'withoutAuthOnly',
  data () {
    return {
      useV2: AUTH_CONTAINER_V2,

      title: this.$t('auth.login'),
      loading: false, // for mixin
      email: '',
      password: '',
      tempUserId: '',
      modalTwoFaOpened: false,
      rememberMe: false,

      // shows input for twoFa or device confirmation
      twoFa: false,
      device: false,
      deviceConfirmationCodeExpiresIn: null,

      emailConfirmation: false,
      emailConfirmationCodeExpiresIn: null,
    }
  },
  head () {
    return getDefaultHeadData({
      title: this.$t('meta.login.title'),
      description: this.$t('meta.login.description'),
      locale: this.$i18n.locale,
    })
  },

  computed: {
    ...mapState({
      isFromIeoPage: state => state.ieo.isFromIeoPage,
    }),
    customErrors () {
      return {
        required: this.$t('validators.required'),
        email: this.$t('validators.email'),
        atLeastOneLowercase: this.$t('validators.atLeastOneLowercase'),
        atLeastOneUppercase: this.$t('validators.atLeastOneUppercase'),
        atLeastOneNumeric: this.$t('validators.atLeastOneNumeric'),
        atLeastOneSpecial: this.$t('validators.atLeastOneSpecial'),
        onlyLatinLettersOrNumbersAndChars: this.$t(
          'validators.onlyLatinLettersOrNumbersAndChars',
        ),
      }
    },
    isBtnFullWidth () {
      if (!process.client) return
      return this.$mq.resize && this.$mq.below(991)
    },
    loginData () {
      return {
        email: this.email,
        password: this.password,
        [TEMP_ID_KEY]: this.tempUserId,
      }
    },
    steps () {
      if (this.modalTwoFaOpened && this.device && this.twoFa) {
        return this.$t('auth.steps.multiple')
      }

      if (this.modalTwoFaOpened && this.device) {
        return this.$t('auth.steps.device')
      }

      return this.$t('auth.steps.auth')
    },
  },

  methods: {
    ...mapMutations({
      setIsFromIeoPage: 'ieo/setIsFromIeoPage',
    }),
    closeModal () {
      this.modalTwoFaOpened = false
      this.deviceConfirmationCodeExpiresIn = null
      this.twoFa = false
      this.device = false
      this.emailConfirmation = false
      this.emailConfirmationCodeExpiresIn = null
      this.tempUserId = null
    },

    async handleCodeResend (body) {
      const response = await this.$api.registerResendCode(body)

      if (response?.data[TEMP_ID_KEY]) {
        this.tempUserId = response?.data[TEMP_ID_KEY]
      }
    },

    setRememberMe () {
      const location = domainWithoutSubdomain()

      this.$cookies.set(REMEMBER_ME, this.rememberMe, {
        domain: location,
        path: '/',
      })
    },

    async submit () {
      this.loading = true

      this.setRememberMe()

      try {
        const {
          data,
          status,
          errors,
        } = await this.$authentication.loginWithBasic({
          email: this.email,
          password: this.password,
          ...this.mix_captchaData,
        })

        if (errors) {
          this.mix_notifyServerErrors(errors)
          return
        }

        if (data && data[TEMP_ID_KEY]) {
          this.tempUserId = data[TEMP_ID_KEY]
        }

        if (Object.values(STATUS_CODE).includes(status)) {
          this.modalTwoFaOpened = true

          switch (status) {
            case STATUS_CODE.USER_IS_BLOCKED:
              notifyError({
                text: this.$t('auth.blocked'),
              })
              break

            case STATUS_CODE.MAIL_NOT_CONFIRMED:
              this.emailConfirmationCodeExpiresIn = data?.life_time
                ? parseFloat(data.life_time)
                : 60
              this.emailConfirmation = true

              await this.handleCodeResend({
                email: this.email,
                [TEMP_ID_KEY]: this.tempUserId,
              })
              break

            case STATUS_CODE.DEVICE_NOT_CONFIRMED:
              this.device = true
              this.deviceConfirmationCodeExpiresIn = data?.life_time
                ? parseFloat(data.life_time)
                : null
              break

            case STATUS_CODE.TWO_FA_NOT_CONFIRMED:
              this.twoFa = true
              break

            case STATUS_CODE.DEVICE_AND_TWO_FA_NOT_CONFIRMED:
              this.device = true
              this.twoFa = true
              this.deviceConfirmationCodeExpiresIn = data?.life_time
                ? parseFloat(data.life_time)
                : null
              break
          }
        } else {
          const redirect = this.$router.currentRoute.query.redirect
          const referralCallback = this.$router.currentRoute.query
            .referralCallback
          await this.$store.dispatch('settings/verification/actionKycStatus')

          // if (response.locale) await this.MixinNavLangSwitch_setLocale(response.locale)

          await this.localePush(redirect || '/exchange')

          // if it comes from ieo page - after login redirects back to this page
          if (
            this.isFromIeoPage &&
            this.isFromIeoPage.name.includes('launchpad')
          ) {
            const ieoPagePath = this.isFromIeoPage.path
            await this.setIsFromIeoPage(undefined)

            this.localePush(ieoPagePath)
          } else {
            const goTo = redirect || '/exchange'
            this.localePush({ path: goTo }, () => {
              referralCallback &&
                this.$store.dispatch(referralCallback, {}, { root: true })
            })
          }
        }
      } catch ({ response }) {
        const status = response?.data?.status
        if (STATUS_CODE.USER_IS_BLOCKED === status) {
          notifyError({
            text: this.$t('auth.blocked'),
          })
        }
        if (response?.data?.errors) {
          const errors = response.data.errors
          this.mix_setServerErrors(errors)

          // show error messages
          if (errors?.message) {
            this.mix_notifyServerErrors(errors)
          }
        }
      } finally {
        this.loading = false
      }
    },
  },

  validations () {
    return this.mix_validationsServerWrapper({
      email: {
        required,
        email,
      },
      password: {
        required,
        onlyLatinLettersOrNumbersAndChars: this.$validators
          .onlyLatinLettersOrNumbersAndChars,
        ...this.$validators.passwordValidations,
      },
      mix_captchaData: {},
    })
  },
}
</script>

<style lang="scss" scoped>
.auth-login {
  display: flex;
  flex: 1;
}

.login-text__wrapper,
.login-text__reset-wrapper {
  display: flex;
  align-items: center;
}

.login-text__reset-wrapper {
  justify-content: space-between;

  .login-text__remember-device-wrapper {
    display: flex;

    .exc-checkbox {
      top: toRem(-2px);
    }

    span {
      font-weight: 400;
      font-size: toRem(14px);
      line-height: toRem(18px);
      display: flex;
      align-items: center;
    }
  }

  a {
    font-size: toRem(14px);
    font-weight: 600;
    line-height: toRem(18px);
  }
}
</style>
